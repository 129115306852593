<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">New Company</v-btn>
    </template>
    <v-card>
      <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
        <v-toolbar-title class="headline primary--text">Create Company</v-toolbar-title>
        <v-spacer></v-spacer>
        <toolbar-icon-button tooltip="Save" icon="mdi-content-save" color="primary" @click="save" />
        <toolbar-icon-button tooltip="Close" icon="mdi-close" color="error" @click="close" />
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-text-field v-model="editedItem.name" label="Name"></v-text-field>
          <v-text-field v-model="editedItem.code" label="Code" ></v-text-field>
          <v-select v-model="editedItem.company_type" :items="companyTypes" label="Type" clearable></v-select>
          <v-autocomplete v-model="editedItem.tenant_id" :items="tenants" item-text="name" item-value="id" label="Tenant"></v-autocomplete>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'CreateCompany',
    data() {
      return {
        tenants: [],
        dialog: false,
        editedItem: {
          name: '',
          code: '',
          company_type: '',
          tenant_id: 1,
        },
        defaultItem: {
          name: '',
          code: '',
          company_type: '',
          tenant_id: 1,
        },
      };
    },
    computed: {
      ...mapGetters(['companyTypes']),
    },
    watch: {
      dialog(val) {
        if (!val) this.close();
      },
    },
    created() {
      this.initialize();
    },
    methods: {
      async initialize() {
        try {
          let response = await this.$http.get('/admin/companies');
          this.tenants = response.data.tenants;
        } catch (error) {
          this.$toast.error('Failed to load tenants.');
        }
      },
      close() {
        if (!this.dialog) return; // Prevent unnecessary closing
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
        });
      },
      async save() {
        try {
          const companyData = {
            name: this.editedItem.name,
            code: this.editedItem.code,
            company_type: this.editedItem.company_type,
            tenant_id: this.editedItem.tenant_id,
          }
          await this.$http.post('/admin/companies', companyData)
          this.$emit('done')
          this.$toast.success('Company Created')
          this.close();
        } catch (error) {
          this.$toast.error('Failed to create company.')
        }
      },
    },
  };
</script>