<template>
  <v-dialog
    v-model="dialogVisible"
    fullscreen
    transition="custom-slide-x-reverse-transition"
    content-class="right-sheet"
    @close="close"
  >
    <v-card>
      <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
        <toolbar-icon-button tooltip="Edit" icon="mdi-pencil" color="primary" @click="updateDevice" tooltipLocation="bottom"/>
        <v-spacer></v-spacer>
        <toolbar-icon-button tooltip="Close" icon="mdi-close" color="error" @click="close" tooltipLocation="bottom"/>
      </v-toolbar>
      <v-card class="cpointer" @click="openProduct" elevation="0">
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-img v-if="dev.product?.image" height="150" :src="dev.product.image" contain></v-img>
            </v-col>
            <v-col cols="9" class="pl-8">
              <h1 class="page-title">
                {{dev.product?.brand?.name}} || {{dev.product?.product_model}}
              </h1>
              <h4 class="mt-5 mb-1">{{dev.product?.name}}</h4> 
              <h4 class="primary--text">{{dev.product?.procat?.name}}</h4> 
            </v-col>
          </v-row>
        </v-card-text>
        <update-product @done="initialize" :item="dev.product" v-model="productDialog" :procats="procats"/>
        <update-device @done="initialize" :item="dev" v-model="deviceDialog" :procats="procats"/>
      </v-card>
      <v-tabs v-model="tab" fixed grow>
        <v-tab>General</v-tab>
        <!-- <v-tab>Plan</v-tab> -->
        <v-tab>Files & Pictures</v-tab>
        <v-tab>Vault</v-tab>
        <v-tab @click="reloadLogs">Activity</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="mt-5">
        <v-tab-item>
          <v-row>
            <v-col cols="6">
              <v-list>
                <v-list-item v-for="(item, index) in evenFilteredProperties" :key="index">
                  <v-list-item-avatar>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="pl-2">{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-title class="primary--text">{{ getPropertyValue(item.property) }}</v-list-item-title>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="6">
              <v-list>
                <v-list-item v-for="(item, index) in oddFilteredProperties" :key="index">
                  <v-list-item-avatar>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="pl-2">{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-title class="primary--text">{{ getPropertyValue(item.property) }}</v-list-item-title>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          
          <v-row v-if="getPropertyValue('note').length > 0" class="mx-3">
            <v-col cols="12">
              <h5>Note</h5>
              <p>{{ dev.note }}</p>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <attachments view="device" class="px-3"/>
        </v-tab-item>
        <v-tab-item>
          <secrets view="device"/>
        </v-tab-item>
        <v-tab-item>
          <logs view="device"/>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import Attachments from '@/components/Multimodal/Attachments'
import Secrets from '@/components/Multimodal/Secrets'
import Logs from '@/components/Multimodal/Logs'
import UpdateProduct from '@/components/Forms/Product/UpdateProduct'
import UpdateDevice from '@/components/Forms/Device/UpdateDevice.vue'

export default {
  name: 'DeviceCard',
  components: {
      Attachments,
      Secrets,
      Logs,
      UpdateProduct,
      UpdateDevice,
    },
  props: {
    value: { type: Boolean, required: true },
  },
  data() {
    return {
      localValue: false,
      nav: 'recent',
      dev: {},
      procats: [],
      suppliers: [],
      tab: 0,
      properties: [
        {icon: "mdi-devices", text: "Name", property: 'name' },
        {icon: "mdi-xml", text: "Code", property: 'code' },
        {icon: "mdi-barcode", text: "Serial Number", property: 'sn' },
        {icon: "mdi-chip", text: "Mac Adress", property: 'mac' },
        {icon: "mdi-lan", text: "IP address", property: 'ip'},
        {icon: "mdi-ethernet", text: "Patch", property: 'patch'},
        {icon: "mdi-server", text: "Port", property: 'port'},
        {icon: "mdi-calendar-edit", text: "Purchase Date", property: 'purchase_date'},
        {icon: "mdi-calendar-alert", text: "Warranty Expires", property: 'warranty_expiration_date'},
      ],
      statusMenu: false,
      productDialog: false,
      deviceDialog: false,
    }
  },

  computed: {
    ...mapGetters(['site', 'device', 'sitePermission']),
    dialogVisible: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    filteredProperties() {
      return this.properties.filter(item => this.getPropertyValue(item.property).length > 0)
    },
    oddFilteredProperties() {
      return this.filteredProperties.filter((item, index) => index % 2 !== 0);
    },
    evenFilteredProperties() {
      return this.filteredProperties.filter((item, index) => index % 2 === 0);
    },
  },

  watch: {
    dialogVisible(newVal) {
      newVal ? this.reload() : this.close()
    },
    device(){
      this.reload()
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    close() {
      this.$store.commit('closeDeviceDialog')
    },
    async initialize() {
      this.reload()
      let response = await this.$http.get('/site/devices/new')
      this.procats = response.data.procats
      this.suppliers = response.data.suppliers
      this.loading = false
    },
    reload() {
      this.dev = this.device
    },

    openProduct() {
      this.productDialog = true
    },

    updateDevice() {  
      this.deviceDialog = true
    },

    getPropertyValue(property) {
      if (property === 'purchase_date' || property === 'warranty_expiration_date') {
        return this.dev[property] ? this.$options.filters.moment(this.dev[property], this.$store.getters.dateFormat) : ''
      }
      return this.dev[property] || ''
    },

    async reloadLogs() {
      const Device = new FormData()
      Device.append("model", "devices")
      Device.append("id", this.device.id)
      let response = await this.$http.post('/site/devices/logs', Device)
      //needs error handling
      this.$store.commit('setDeviceLogs', response.data.logs)
    },
  }
}
</script>

<style src="./Cards.scss" lang="scss"></style>