<template>
  <v-dialog v-model="dialogVisible" @close="close" max-width="500px">
    <v-card>
      <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
        <v-toolbar-title class="headline primary--text">Update {{ editedItem.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <toolbar-icon-button tooltip="Save" icon="mdi-content-save" color="primary" @click="save" />
        <toolbar-icon-button tooltip="Close" icon="mdi-close" color="error" @click="close" />
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-text-field v-model="editedItem.name" label="Name"></v-text-field>
          <v-text-field v-model="editedItem.code" label="Code" ></v-text-field>
          <v-select v-model="editedItem.company_type" :items="companyTypes" label="Type" clearable></v-select>
          <v-autocomplete v-model="editedItem.tenant_id" :items="tenants" item-text="name" item-value="id" label="Tenant"></v-autocomplete>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'UpdateCompanyForm',
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      item: {
        type: Object,
        required: true,
      },  
    },
    data() {
      return {
        tenants: [],
        saving: false,
        editedItem:{},
      }
    },
    computed: {
      ...mapGetters(['companyTypes']),
      dialogVisible: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
    },

    watch: {
      dialogVisible(newVal) {
        if (newVal) {
          this.editedItem = Object.assign({}, this.item);
        }
      }
    },

    created () {
      this.initialize()
    },
    methods: {
      async initialize () {
        try {
          const response = await this.$http.get('/admin/companies');
          this.tenants = response.data.tenants;
        } catch (error) {
          this.$toast.error("Error fetching tenants.")
        }
      },
      close () {
        this.dialogVisible = false
      },
      async save () {
        if (!this.editedItem.name || !this.editedItem.code) {
          this.$toast.error('Please fill in all required fields.');
          return;
        }

        this.saving = true
        try {
          const companyData = {
            id: this.editedItem.id,
            name: this.editedItem.name,
            code: this.editedItem.code,
            company_type: this.editedItem.company_type,
            tenant_id: this.editedItem.tenant_id,
          }
          await this.$http.put('/admin/companies', companyData)
          this.$emit('done')
          this.$toast.success('Company Updated')
          this.close()
        } catch (error) {
          this.$toast.error('Failed to update company.')
        } finally {
          this.saving = false;
        }
      },
    },
  }
</script>