<template>
  <v-card flat :dark="dark" class="abs-parent">
    <v-carousel v-model="index" height="90vh" show-arrows-on-hover hide-delimiters>
      <v-carousel-item v-for="(item,i) in items" :key="i">
        <v-img :src="item.url" contain max-height="85vh" class="mt-5"></v-img>
      </v-carousel-item>
    </v-carousel>
    <v-btn icon class="abs-btn" @click="close">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-btn icon class="abs-btn2" @click="dark = !dark">
      <v-icon>mdi-theme-light-dark</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'ImageGallery',
    components: {
    },
    data: () => ({
      loading: false,
      dark: true
    }),
    props: ['items', 'index'],
    computed: {
      ...mapGetters(['site', 'device'])
    },
    methods: {
      close () {
        this.$emit('close')
      },
    }

  }
</script>

<style scoped lang="scss">
  .abs-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .abs-btn2 {
    position: absolute;
    right: 50px;
    top: 10px;
  }
  .abs-parent {
    position: relative;
  }
</style>