<template>
  <v-container fluid class="admin-page">
    <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
      <h1 class="page-title">Administration</h1>
      <!-- <v-menu
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              class="text-capitalize button-shadow"
          >Actions</v-btn>
        </template>
        <v-list>
          <v-list-item
              link
              @click="setSiteAccesses()"
          >
            <v-list-item-title>set site accesses</v-list-item-title>
          </v-list-item>
          <v-list-item
              link
              @click="convertSharesToAccesses()"
          >
            <v-list-item-title>convert shares to accesses</v-list-item-title>
          </v-list-item>
          <v-list-item
              link
              @click="addNameToRessources()"
          >
            <v-list-item-title>add name to ressources</v-list-item-title>
          </v-list-item>
          <v-list-item
              link
              @click="convert_activity_logs()"
          >
            <v-list-item-title>convert Logs</v-list-item-title>
          </v-list-item>
          <v-list-item
              link
              @click="add_floor_to_zones()"
          >
            <v-list-item-title>Add Floor To Zones</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </v-row>
    <div>
      <!-- <v-btn @click="showSheet = true">Open Right Sheet</v-btn>

      <device-card v-model="showSheet" /> -->
      
    </div>
    <v-row>
      <v-col cols="3">
        <v-list>
          <inline-text-input v-model="name"/>
          <inline-text-input />
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import DeviceCard from '@/components/Cards/DeviceCard.vue'

  export default {
    name: 'Admin',
    components: {
      DeviceCard
    },
    data () {
      return {
        showSheet: false,
        name: 'Bose Freespace 51',  
      }
    },
    mounted() {
      this.$store.commit('setHeaderTitle', 'Admin')
    },

    methods: {
      async setSiteAccesses() {
        let response = await this.$http.get('/admin/jobs/set_site_accesses')
        this.$toast.success(response.data.message);
      },
      async convertSharesToAccesses() {
        let response = await this.$http.get('/admin/jobs/convert_shares_accesses')
        this.$toast.success(response.data.message);
      },
      async addNameToRessources() {
        let response = await this.$http.get('/admin/jobs/add_name_to_ressources')
        this.$toast.success(response.data.message);
      },
      async convert_activity_logs() {
        let response = await this.$http.get('/admin/jobs/convert_activity_logs')
        this.$toast.success(response.data.message);
      },
      async add_floor_to_zones() {
        let response = await this.$http.get('/admin/jobs/add_floor_to_zones')
        this.$toast.success(response.data.message);
      }
    }
  }
</script>