<template>
  <v-container fluid>
    <v-data-iterator
      :items="attachments"
      :search="search"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:header>
        <v-toolbar flat height="90">    
          <v-icon v-if="view === 'site'" large class="mx-4">mdi-menu</v-icon>         
          <v-spacer></v-spacer>
          <table-search-field v-model="search"></table-search-field>
          <v-spacer></v-spacer>
          <v-btn
            v-if="view === 'site' && sitePermission>1"
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true; selectorKey++"
          >
            Add Attachment
          </v-btn>
        </v-toolbar>
        <v-dialog v-model="dialog" width="600px">
          <s3-attacher 
            directory="attachments" 
            label="Upload file" 
            :site_id="site.id"
            :attachable_type="attachable_type" 
            :floored="floored" 
            :attachable_id="attachable_id"
            @close="dialog=false"/>
        </v-dialog>
        <v-dialog v-model="gallery">
          <image-gallery :items="images" @close="gallery=false" :index="galleryIndex" />
        </v-dialog>
        <v-dialog v-model="editDialog" width="600px">
          <v-card>
            <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
              <v-toolbar-title class="headline primary--text">Edit Attachment</v-toolbar-title>
              <v-spacer></v-spacer>
              <toolbar-icon-button tooltip="Delete" icon="mdi-delete" color="error" @click="deleteDialog = true"/>
              <toolbar-icon-button tooltip="Save" icon="mdi-content-save" color="primary" @click="updateAttachment" />
              <toolbar-icon-button tooltip="Close" icon="mdi-close" color="error" @click="closeEditDialog" />
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-col cols="12">
                  <v-text-field v-model="editedItem.filename" label="Filename" prepend-icon="mdi-form-textbox"/>
                </v-col>
                <v-col cols="12">
                  <multimodal-selector :selector_key.sync="selectorKey" :polymorphic_id.sync="editedItem.attachable_id" :polymorphic_type="editedItem.attachable_type" @linked="editAttachable"/>
                </v-col>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" width="600px">
          <v-card class="text-center py-3">
            <v-icon style="font-size: 64px" color="error" class="my-5">mdi-delete</v-icon>
            <v-card-title>
              <v-spacer />
              <div class="text-center">
                <h3 class="headline error--text mb-0">Delete {{editedItem.filename}}.{{editedItem.file_type}}</h3>
                <h3 class="headline error--text mb-0">Are you Sure ?</h3>
                <div>Deleted items cannot be retrieved</div>
              </div>
              <v-spacer />
            </v-card-title>
            <v-card-actions class="mt-5">
              <v-spacer />
              <v-btn @click="deleteDialog = false">Cancel</v-btn>
              <v-btn @click="deleteAttachment" color="error">Confirm</v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:default="props">
        <v-sheet>
          <v-row class="mt-5">
            <v-col
              class="text-center"
              :cols="columnSpan"
              v-if="sitePermission>1"
            >
              <v-card flat class="text-center" @click="dialog = true">
                <v-sheet height="150" class="d-flex align-center justify-center">
                  <v-icon style="font-size: 64px" color="primary" class="my-5">mdi-plus</v-icon>
                </v-sheet>
                <v-card-text class="primary--text">
                  Add File
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              v-for="item in props.items"
              :key="item.id"
              :cols="columnSpan"
            >
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card flat>
                    <v-sheet class="d-flex flex-column align-center">
                      <v-img
                        v-if="is_image(item.url)"
                        height="150"
                        :src="item.url"
                        class="rounded-lg"
                      >
                      </v-img>
                      <v-sheet v-else-if="is_video(item.url)" height="150">
                        <video
                          max-height="150"
                          width="100%"
                          controls
                          class="rounded-lg"
                        >
                          <source :src="item.url" :type="'video/'+returnExtention(item.url)">
                        </video>
                      </v-sheet>
                      <v-sheet v-else height="150">
                        <v-icon style="font-size: 64px" color="primary" class="mt-6">{{returnExtIcon(item.url)}}</v-icon>
                        <div class="text-center primary--text">{{returnExtention(item.url)}}</div> 
                      </v-sheet>
                    </v-sheet>

                    <v-card-subtitle class="text-center">
                      {{item.filename}}
                    </v-card-subtitle>

                    <v-fade-transition>
                      <v-overlay v-if="hover" absolute color="primary" opacity="0.65" class="rounded-lg"> 
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" color="white" class="mr-3" @click="showMedia(item)">
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>View</span>
                        </v-tooltip>
                        <v-tooltip top v-if="sitePermission>1">
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" color="white" @click="editAttachment(item)">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <a :href="item.url" :download="item.filename" target="_blank">
                              <v-btn icon v-on="on" color="white" class="ml-3">
                                <v-icon>mdi-download</v-icon>
                              </v-btn>
                            </a>
                          </template>
                          <span>Download</span>
                        </v-tooltip>
                      </v-overlay>
                    </v-fade-transition>
                  </v-card>  
                </template>
              </v-hover>
            </v-col>
          </v-row>
        </v-sheet>
      </template>
      <template v-slot:no-data>
        <v-row class="mt-5">
          <v-col
            class="text-center"
            cols="12"
            md="4"
            lg="2"
            v-if="sitePermission>1"
          >
            <v-card flat class="text-center" @click="dialog = true">
              <v-sheet height="150" class="d-flex align-center justify-center">
                <v-icon style="font-size: 64px" color="primary" class="my-5">mdi-plus</v-icon>
              </v-sheet>
              <v-card-text class="primary--text">
                Add File
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'
  import S3Attacher from '@/components/S3Upload/S3Attacher'
  import MultimodalSelector from '@/components/Selectors/MultimodalSelector'
  import ImageGallery from '@/components/Media/ImageGallery'

  export default {
    name: "Attachments",
    components: {
      S3Attacher,
      MultimodalSelector,
      ImageGallery
    },
    data() {
      return {
        search: '',
        filter: {},
        loading: true,
        dialog: false,
        editDialog: false,
        deleteDialog: false,
        editedItem: {},
        gallery: false,
        galleryIndex: 0,
        selectedFloor: null,
        selectorKey: 0,
      };
    },
    props: ['view'],
    computed: {
      ...mapGetters(['site', 'device', 'floor', 'location', 'sitePermission']),
      attachments () {
        if (this.view === 'site') {
          return this.site.attachments
        } else if (this.view === 'location') {
          if (this.floor.id) {
            if (this.location.id) {
              return this.site.attachments.filter( o => o.attachable_id == this.location.id && o.attachable_type == 'Location')
            } else {
              return this.site.attachments.filter( o => o.attachable_id == this.floor.id && o.attachable_type == 'Floor')
            }
          } else {
            return []
          }
        } else if (this.view === 'device') {
          return this.site.attachments.filter(o => o.attachable_id === this.device.id && o.attachable_type == 'Device')
        } else {
          return []
        }
      },
      attachable_type () {
        if (this.view === 'site') {
          return null
        } else if (this.view === 'location') {
          if (this.floor.id) {
            if (this.location.id) {
              return 'Location'
            } else {
              return 'Floor'
            }
          } else {
            return null
          }
        } else if (this.view === 'device') {
          return "Device"
        } else {
          return null
        }
      },
      attachable_id () {
        if (this.view === 'site') {
          return null
        } else if (this.view === 'location') {
          if (this.floor.id) {
            if (this.location.id) {
              return this.location.id
            } else {
              return this.floor.id
            }
          } else {
            return null
          }
        } else if (this.view === 'device') {
          return this.device.id
        } else {
          return null
        }
      },
      floored () {
        if (this.view === 'location') {
          if (this.location.id) {
            return this.location.floor_id
          } else {
            return null
          }
        } else {
          return null
        } 
      },
      locationsOnFloor () {
        if (this.selectedFloor) {
          let floorIndex = this.site.floors.findIndex(a => a.id == this.selectedFloor)
          return this.site.floors[floorIndex].locations
        } else {
          return []
        }
      },
      images() {
        return this.attachments.filter(o => this.is_image(o.url))
      },
      columnSpan() {
        return this.view === 'site' ? 2 : 4
      }

    },
    methods: {
      initialize () {
        this.loading = false
      },
      returnFilename (url) {
        return url.split("/").pop().split(".").shift()
      },
      returnExtention (url) {
        return url.split("/").pop().split(".").pop()
      },
      is_image (url) {
        let ext = url.split("/").pop().split(".").pop().toLowerCase()
        let images = ["jpg", "png", "jpeg", "gif", "heic", "svg", "bmp"]
        return images.indexOf(ext) > -1
      },
      is_video (url) {
        let ext = url.split("/").pop().split(".").pop().toLowerCase()
        let videos = ["mp4", "mov", "webm", "ogg", "mpg"]
        return videos.indexOf(ext) > -1
      },
      is_audio (url) {
        let ext = url.split("/").pop().split(".").pop().toLowerCase()
        let audios = ["mp3", "wav", "wma", "mid"]
        return audios.indexOf(ext) > -1
      },
      returnExtIcon (url) {
        let ext = url.split("/").pop().split(".").pop().toLowerCase()
        let icon = ""
        switch(ext) {
          case "doc":
          case "docx":
            icon = "mdi-file-word"
            break
          case "odt":
          case "txt":
          case "plain":
          case "json":
            icon = "mdi-file-document-outline"
            break
          case "pdf":
            icon = "mdi-file-pdf-box"
            break
          case "xlsx":
          case "xls":
          case "xlsm":
          case "csv":
            icon = "mdi-file-excel"
            break
          case "pps":
          case "ppt":
          case "pptx":
            icon = "mdi-file-powerpoint"
            break
          default:
            icon = "mdi-file-document-multiple"
        }
        return icon
      },
      showMedia(item) {
        if (this.is_image(item.url)) {
          this.galleryIndex = this.images.findIndex(o => o.id == item.id)
          this.gallery = true
        } else {
          window.open(item.url, '_blank')
        }
      },
      async downloadMedia(item) {
        window.open(item.url)
      },
      editAttachment(item) {
        this.editedItem = Object.assign({}, item)
        this.selectorKey = this.selectorKey + 1
        this.editDialog = true
      },
      editAttachable(type, id) {
        this.editedItem.attachable_type = type
        this.editedItem.attachable_id = id
      },
      resetFloorId() {
        this.selectedFloor = null
        this.editedItem.attachable_id = null
      },
      async deleteAttachment() {
        let response = await this.$http.delete('/global/attachments', {params: {id: this.editedItem.id}})
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$store.commit('deleteAttachment', response.data.attachment.id)
          this.$toast.success("Attachment Deleted")
        }
        this.closeEditDialog()
      },
      async updateAttachment() {
        const Attachment = new FormData()
        Attachment.append("id", this.editedItem.id)
        Attachment.append("filename", this.editedItem.filename)
        Attachment.append("attachable_type", this.editedItem.attachable_type)
        Attachment.append("attachable_id", this.editedItem.attachable_id)
        let response = await this.$http.put('/global/attachments', Attachment)
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$store.commit('updateAttachment', response.data.attachment)
          this.$toast.success("Attachment Updated")
        }
        this.closeEditDialog()

      },
      closeEditDialog() {
        this.editDialog = false
        this.deleteDialog = false
        this.editedItem = {}
        this.selectedFloor= null
      }
    },
    mounted() {
      this.initialize()
    },
  };
</script>