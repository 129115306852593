<template>
  <v-dialog v-model="dialogVisible" @close="close" width="80%">
    <v-card>
      <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
        <v-toolbar-title class="headline primary--text">Update Device</v-toolbar-title>
        <v-spacer></v-spacer>
        <toolbar-icon-button tooltip="Save" icon="mdi-content-save" color="primary" @click="save" />
        <toolbar-icon-button tooltip="Close" icon="mdi-close" color="error" @click="closeDialog" />
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-text-field v-model="editedItem.code" prepend-icon="mdi-pound" label="Unique Identifier"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="editedItem.sn" prepend-icon="mdi-barcode" label="Serial Number"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="editedItem.mac" prepend-icon="mdi-xml" label="Mac Address"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="editedItem.ip" prepend-icon="mdi-lan" label="Ip Address"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="editedItem.patch" prepend-icon="mdi-sitemap" label="Patch"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="editedItem.port" prepend-icon="mdi-server" label="Port"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="editedItem.note" prepend-icon="mdi-note" label="Note"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-autocomplete v-model="editedItem.company_id" :items="suppliers" item-text="name" item-value="id" label="Supplier" prepend-icon="mdi-truck" />
          </v-col>
          <v-col cols="4">
            <date-picker-menu v-model="editedItem.purchase_date" label="Purchase Date" />
          </v-col>
          <v-col cols="4">
            <date-picker-menu v-model="editedItem.warranty_expiration_date" label="Warranty Expiration Date" />
          </v-col>
          <v-col cols="6">
            <v-autocomplete v-model="editedItem.floor_id" :items="site.floors" label="Select Floor" prepend-icon="mdi-tag-heart-outline" item-text="name" item-value="id" clearable  >
              <template v-slot:selection="data"> {{ data.item.name }} || {{ data.item.code }}</template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ data.item.code }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete v-model="editedItem.location_id" :items="locationsOnFloor" label="Select Location" prepend-icon="mdi-tag-heart-outline" item-text="name" item-value="id" clearable>
              <template v-slot:selection="data"> {{ data.item.name }} || {{ data.item.code }} </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ data.item.code }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-select v-model="editedItem.zone_id" :items="site.zones" label="Select Zone" prepend-icon="mdi-tag-heart-outline" item-text="name" item-value="id" clearable/>
          </v-col>
          <v-col cols="6">
            <v-select v-model="editedItem.layer_id" :items="site.layers" label="Select Layer" prepend-icon="mdi-tag-heart-outline" item-text="name" item-value="id" clearable/>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  

  export default {
    name: 'NewDevice',
    props: {
      value: { type: Boolean, required: true },
      item: { type: Object, required: true },
    },
    data: () => ({
      editedItem: {
        name: '',
        product_id: '',
        floor_id: '',
        location_id: '',
        zone_id: '',
        layer_id: '',
        company_id: '',
        code: '',
        sn: '',
        mac: '',
        ip: '',
        patch: '',
        port: '',
        note: '',
        purchase_date: '',
        warranty_expiration_date: ''
      },
      defaultItem: {
        name: '',
        product_id: '',
        floor_id: '',
        location_id: '',
        zone_id: '',
        layer_id: '',
        company_id: '',
        code: '',
        sn: '',
        mac: '',
        ip: '',
        patch: '',
        port: '',
        note: '',
        purchase_date: '',
        warranty_expiration_date: ''
      },
      procats: [],
      suppliers: [],
      brands: [],
      loading: true,
      localValue: false,
    }),
    computed: {
      dialogVisible: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      locationsOnFloor () {
        if (this.editedItem.floor_id) {
          let floorIndex = this.site.floors.findIndex(a => a.id == this.editedItem.floor_id)
          return this.site.floors[floorIndex].locations
        } else {
          return []
        }
      },
      ...mapGetters(['site', 'floor', 'location', 'StateUser', 'device']),
    },
    watch: {
      floor(){
        this.editedItem.floor_id = this.floor.id
        this.defaultItem.floor_id = this.floor.id
      },
      location() {
        this.editedItem.location_id = this.location.id
        this.defaultItem.location_id = this.location.id
      },
      dialogVisible(newVal) {
        if (newVal) {
          this.editedItem = Object.assign({}, this.item);
        }
      }
    },
    created () {
      this.initialize()
    },
    methods: {
      async initialize () {
        let response = await this.$http.get('/site/devices/new')
        this.procats = response.data.procats
        this.suppliers = response.data.suppliers
        this.brands = response.data.brands
        this.loading = false
      },
      async save() {
        const Device = new FormData()
        Device.append('id', this.editedItem.id)
        Device.append('name', this.editedItem.name)
        Device.append('product_id', this.editedItem.product_id)
        Device.append('floor_id', this.editedItem.floor_id)
        Device.append('location_id', this.editedItem.location_id)
        Device.append('zone_id', this.editedItem.zone_id)
        Device.append('layer_id', this.editedItem.layer_id)
        Device.append('site_id', this.site.id)
        Device.append('company_id', this.editedItem.company_id)
        Device.append('code', this.editedItem.code)
        Device.append('sn', this.editedItem.sn)
        Device.append('mac', this.editedItem.mac)
        Device.append('ip', this.editedItem.ip)
        Device.append('patch', this.editedItem.patch)
        Device.append('port', this.editedItem.port)
        Device.append('note', this.editedItem.note)
        Device.append('purchase_date', this.editedItem.purchase_date)
        Device.append('warranty_expiration_date', this.editedItem.warranty_expiration_date)
        let response = await this.$http.put('/site/devices', Device )
        this.$store.commit('createDevice', response.data.device)
        this.dialogVisible = false
        this.$toast.success("Device Updated")
      },
      closeDialog() {
        this.dialogVisible = false
      },
    }
  }
</script>