<template>
  <v-container fluid class="admin-page">
    <h1 class="page-title mt-10 mb-6">Release Notes</h1>
    <h3>Wikibuild 0.1.4</h3>
    <ul class="mb-12">
      <li>Start of mobile layout</li>
    </ul>
    <h3>Wikibuild 0.1.3</h3>
    <ul>
      <li>Release Notes page in Administration</li>
      <li>In Site/Devices show total devices without archived if not showing</li>
      <li>Brand for devices is now a relation to a company and not and editable field</li>
      <li>Add global components ToolbarIcon, DatePickerMenu and ToolbarSearch</li>
      <li>Start on the DeviceCard separated from the device form</li>
      <li>Start on the UpdateDevice form</li>
      <li>CreateCompany and UpdateCompany are modified to be OK with new Database structure, companies table is not yet visible for users only in administration</li>
      <li>Import and export Companies in Administration</li>
      <li>CompanySelectandCreate that allows selection or creation on the fly for all companies Customers, Brands, Suppliers and contractors</li>
      <li>attachments, vault and logs are modified to work well in the DeviceCard</li>
      <li>S3Attacher had a little bug on computed properties and was corrected</li>
    </ul>
  </v-container>
</template>

<script>
  
  export default {
    name: 'ReleaseNotes',
    data () {
      return {
        loading: false,
      }
    },
    mounted() {
    },
    methods: {
    }
  }
</script>