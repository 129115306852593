<template>
  <v-app>
    <Header />
    <v-main class="mobile-content">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  import Header from '@/components/Header/Header'
  import './Layout.scss';

  export default {
    name: 'Layout',
    components: { Header },
    computed: {
    },
  }
</script>

<style src="./Layout.scss" lang="scss" />