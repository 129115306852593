<template>
  <v-dialog v-model="dialog" width="80%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark class="mb-2" :block="block" v-bind="attrs" v-on="on">New Product</v-btn>
    </template>
    <v-card>
      <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
        <v-toolbar-title class="headline primary--text">New Product</v-toolbar-title>
        <v-spacer></v-spacer>
        <toolbar-icon-button tooltip="Toggle short and long form" icon="mdi-arrow-expand-horizontal" color="info" @click="short = !short" />
        <toolbar-icon-button tooltip="Save" icon="mdi-content-save" color="primary" @click="save" />
        <toolbar-icon-button tooltip="Close" icon="mdi-close" color="error" @click="close" />
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="3" class="pr-4">
              <image-uploader ref="imgUploader" :obj="editedItem" directory="products" @located="setFileLocation"/>
            </v-col>
            <v-col cols="9" v-if="short">
              <company-select-and-create v-model="editedItem.brand_id" label="Brand*" :tenant_id="1" itemType="Brand"/>
              <v-text-field v-model="editedItem.product_model" label="Model*" prepend-icon="mdi-package"></v-text-field>
              <v-text-field v-model="editedItem.name" label="Short Description*" prepend-icon="mdi-package-variant"></v-text-field>
              <v-autocomplete v-model="editedItem.procat_id" :items="procats" item-text="name" item-value="id" label="Product Category*" prepend-icon="mdi-format-list-bulleted-type"></v-autocomplete>
              <v-text-field v-model="editedItem.product_url" label="Product Webpage" prepend-icon="mdi-web"></v-text-field> 
            </v-col>
            <v-col cols="9" v-else>
              <v-tabs fixed-tabs v-model="tabs">
                <v-tab>General</v-tab>
                <v-tab>Specifications</v-tab>
                <v-tab>Description</v-tab>
                <v-tab>I/O</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabs">
                <v-tab-item key="1" class="pt-6">
                  <v-row>
                    <v-col cols="12">
                      <company-select-and-create v-model="editedItem.brand_id" label="Brand*" :tenant_id="1" itemType="Brand"/>
                      <v-text-field v-model="editedItem.product_model" label="Model*" prepend-icon="mdi-package"></v-text-field>
                      <v-text-field v-model="editedItem.name" label="Short Description*" prepend-icon="mdi-package-variant"></v-text-field>
                      <v-autocomplete v-model="editedItem.procat_id" :items="procats" item-text="name" item-value="id" label="Product Category*" prepend-icon="mdi-format-list-bulleted-type"></v-autocomplete>
                      <v-text-field v-model="editedItem.product_url" label="Product Webpage" prepend-icon="mdi-web"></v-text-field> 
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item key="2" class="pt-6">
                  <v-row>
                    <v-col cols=6>
                      <v-text-field v-model="editedItem.height" label="Height" prepend-icon="mdi-axis-z-arrow" suffix="mm"></v-text-field>
                      <v-text-field v-model="editedItem.width" label="Width" prepend-icon="mdi-axis-y-arrow" suffix="mm"></v-text-field>
                      <v-text-field v-model="editedItem.depth" label="Depth" prepend-icon="mdi-axis-x-arrow" suffix="mm"></v-text-field>
                      <v-text-field v-model="editedItem.weight" label="Weight" prepend-icon="mdi-weight" suffix="g"></v-text-field>
                    </v-col>
                    <v-col cols=6>
                      <v-checkbox v-model="editedItem.rack_mounted" label="Rack Mounted" ></v-checkbox>
                      <v-text-field type="number" v-model="editedItem.rack_units" :disabled="!editedItem.rack_mounted" label="Rack Units" suffix="U" prepend-icon="mdi-server"></v-text-field>
                      <v-text-field v-model="editedItem.power" label="Power Consumption" prepend-icon="mdi-power-plug" ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="pt-0">
                      <v-text-field v-model="editedItem.sku" label="SKU" prepend-icon="mdi-web"></v-text-field>
                    </v-col>
                    <v-col cols="4" class="pt-0">
                      <v-text-field v-model="editedItem.ean" label="EAN" prepend-icon="mdi-web"></v-text-field>
                    </v-col>
                    <v-col cols="4" class="pt-0">
                      <v-text-field v-model="editedItem.upc" label="UPC" prepend-icon="mdi-web"></v-text-field>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item key="3" class="pt-10">
                  <v-row>
                    <v-col cols="12">
                      <v-textarea outlined v-model="editedItem.description" label="Description"></v-textarea>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item key="4" class="pt-6">
                  <v-row>
                    <v-col cols=2>
                      <v-btn outlined tile block color="primary" @click="addIO">Add I/O</v-btn>
                      <v-dialog v-model="interfaceDialog" max-width="1000px">
                        <template v-slot:activator="{ on }">
                          <v-btn outlined tile block color="primary" class="mt-4" v-on="on">add multiple I/O</v-btn>
                        </template>
                        <v-card>
                          <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
                            <v-toolbar-title class="headline primary--text">add multiple I/O</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" @click="addMultipleInterfaces" color="primary">
                                  <v-icon>mdi-check</v-icon>
                                </v-btn>
                              </template>
                              <span>Validate</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" @click="closeInterfaceDialog" color="error">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </template>
                              <span>Close</span>
                            </v-tooltip>
                          </v-toolbar>

                          <v-card-text>
                            <v-row class="justify-center">
                              <v-col cols=2>
                                <v-text-field v-model="newInterfaceQty" label="Quantity" prepend-icon="mdi-plus-minus"></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols=3>
                                <v-select v-model="newInterface.interface_type" :items="interfaceIO" label="I/O" prepend-icon="mdi-swap-horizontal"></v-select>
                              </v-col>
                              <v-col cols=3>
                                <v-select v-model="newInterface.terminal" :items="interfaceTerminal" label="Terminal" prepend-icon="mdi-console"></v-select>
                              </v-col>
                              <v-col cols=3>
                                <v-text-field v-model="newInterface.label" label="Label" prepend-icon="mdi-label"></v-text-field>
                              </v-col>
                              <v-col cols=3>
                                <v-select v-model="newInterface.signal" :items="interfaceSignal" label="Signal" prepend-icon="mdi-signal-distance-variant"></v-select>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-col>
                    <v-col cols=10>
                      <v-simple-table dense fixed-header height="250">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left px-0">I/O</th>
                              <th class="text-left px-0">Terminal</th>
                              <th class="text-left px-0">Label</th>
                              <th class="text-left px-0">Signal</th>
                              <th class="text-right pl-0">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in editedItem.interfaces" :key="index">
                              <td class="px-0">
                                <v-select v-model="item.interface_type" :items="interfaceIO" hide-details dense height="32"></v-select>
                              </td>
                              <td class="px-0">
                                <v-select v-model="item.terminal" :items="interfaceTerminal" hide-details dense height="32"></v-select>
                              </td>
                              <td class="px-0">
                                <v-text-field v-model="item.label" dense hide-details height="32"></v-text-field>
                              </td>
                              <td class="px-0">
                                <v-select v-model="item.signal" :items="$store.getters.interfaceSignal" hide-details dense height="32"></v-select>
                              </td>
                              <td width="100" class="text-right pl-0">
                                <v-icon class="mr-2 info--text" @click="cloneIO(item)">mdi-content-duplicate</v-icon>
                                <v-icon class="error--text" @click="discardIO(item, index)">mdi-delete</v-icon>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions v-if="admin">
        <v-switch
          v-model="editedItem.product_status"
          inset
          :label="editedItem.product_status ? 'In Production' : 'Discontinued'"
          class="ml-4"
        ></v-switch>
        <v-spacer></v-spacer>
        <v-autocomplete 
          v-model="editedItem.tenant_id" 
          :items="tenants" 
          item-text="name" 
          item-value="id" 
          label="Tenant" 
          prepend-icon="mdi-shield" >
        </v-autocomplete>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import {mapGetters} from 'vuex'
  import ImageUploader from '@/components/S3Upload/ImageUploader'
  import CompanySelectAndCreate from '@/components/Selectors/companies/CompanySelectAndCreate.vue'

  export default {
    name: 'CreateProduct',
    components: {
      ImageUploader,
      CompanySelectAndCreate,
    },
    props: {
      admin: { type: Boolean, default: false },
      tenants: { type: Array, default: () => [] },
      procats: { type: Array, default: () => [] },
      block: { type: Boolean, default: false }, 
    },
    data() {
      return {
        short: true,
        search: '',
        dialog: false,
        tabs: null,
        interfaceDialog: false,
        editedItem: {},
        newInterfaceQty: 1,
        newInterface: {},
      }
    },

    computed: {
      ...mapGetters(['interfaceIO', 'interfaceTerminal', 'interfaceSignal', 'defaultProduct', 'defaultInterface']),
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      interfaceDialog (val) {
        val || this.closeInterfaceDialog()
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        this.editedItem = { ...this.defaultProduct}
        this.newInterface = { ...this.defaultInterface }
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = { ...this.defaultProduct}
          this.$refs.imgUploader.clear()
          this.tabs = 0
          this.short = true
        })
      },

      setFileLocation (value) {
        this.editedItem.image = value
      },

      async save () {
        const productData = {}
        this.editedItem.company_id = this.editedItem.brand_id
        const fields = [
          'name', 'image', 'product_model', 'product_url', 'product_status', 'power', 'sku', 'upc', 'ean', 
          'description', 'features', 'height', 'width', 'depth', 'weight', 'rack_mounted', 'rack_units', 
          'company_id', 'brand_id', 'procat_id', 'tenant_id',
        ]

        fields.forEach(field => {
          if (this.editedItem[field] !== undefined && this.editedItem[field] !== null && this.editedItem[field] !== "") {
            productData[field] = this.editedItem[field]
          }
        })
         
        try {
          let url = this.admin ? '/admin/products' : '/tenant/products'
          let response = await this.$http.post(url, productData)
          if (response.data.error) {
            Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
          } else {
            this.$emit('done', response.data.product)
            this.$toast.success("Product Created")
            this.$refs.imgUploader.clear()
            this.close()
          }
        } catch (error) {
          this.$toast.error("Error Creating product: "+ error)
        }
      },

      addIO () {
        this.editedItem.interfaces.push({ ...this.defaultInterface })
      },

      closeInterfaceDialog () {
        this.interfaceDialog = false
        this.newInterfaceQty = 1
        this.newInterface = { ...this.defaultInterface }
      },

      addMultipleInterfaces () {
        this.editedItem.interfaces.push(
          ...Array.from({ length: this.newInterfaceQty }, () => ({ ...this.newInterface }))
        )
        this.closeInterfaceDialog()
      },

      cloneIO(item) {
        let clone = Object.assign({}, item)
        delete clone.id
        this.editedItem.interfaces.push(clone)
      },

      discardIO(item, index) {
        this.editedItem.interfaces.splice(index, 1)
        if (item.id) {
          item.discard = true
          this.editedItem.discarded_interfaces.push(item)
        }
      }

    },
  }
</script>