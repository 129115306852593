<template>
  <v-card>
    <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
      <v-toolbar-title class="headline primary--text">Add Attachment</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="saveFile" color="primary" :disabled="disabled">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>Save</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="close" color="error">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Close</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <v-container>
        <div v-if="loading">
          <div class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </div>
        <div v-else>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="filename" label="Filename" prepend-icon="mdi-form-textbox" hint="Leave blank to keep existing filename" persistent-hint/>
            </v-col>
            <v-col cols="12">
              <v-file-input v-model="file" label="Choose File" truncate-text="200" :clearable="false" @change="disabled = false"/>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-card-text>
  </v-card>   
</template>

<script>
  import S3 from 'aws-s3-pro';
  import {mapGetters} from 'vuex'

  export default {
    name: 'S3Attacher',
    data() {
      return {
        loading: false,
        file: null,
        filename: "",
        disabled: true,
      }
    },
    props: ['directory', 'label', 'site_id', 'attachable_type', 'attachable_id', 'floored'],
    computed: {
      ...mapGetters(['s3Config', 'site']),
      S3Client() {
        return new S3(this.s3Config)
      },
      newFileName() {
        return (Math.random().toString().slice(2))
      },
      type: {
        get() {
          return this.attachable_type ? this.attachable_type : null
        },
        set(value) {
          this.$emit('update:attachable_type', value);
        }
      },
      id: {
        get() {
          return this.attachable_id ? this.attachable_id : null
        },
        set(value) {
          this.$emit('update:attachable_id', value);
        }
      },
    },
    mounted() {
    },
    methods: {
      async saveFile() {
        this.loading = true
        this.disabled = true
        let directory = this.directory
        if (process.env.NODE_ENV == "production") {
          directory = directory + "/" + this.site_id
        }
        this.S3Client.config.dirName = directory
        let file = this.file
        let filename = this.filename

        if (filename == "") {
          filename = file.name.split(".").shift()
        }

        this.S3Client
          .uploadFile(file, filename)
          .then(async data => {
            const Attachment = new FormData()
            Attachment.append("url", data.location)
            Attachment.append("file_type", data.location.split('.').pop().toLowerCase())
            Attachment.append("site_id", this.site_id)
            Attachment.append("filename", filename)
            if (this.id) {
              Attachment.append("attachable_id", this.id)
              Attachment.append("attachable_type", this.type)
            }
            let response = await this.$http.post('/global/attachments', Attachment)
            if (response.data.error) {
              Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
            } else {
              this.$store.commit('createAttachment', response.data.attachment)
              this.$toast.success("Document Attached");
              this.close()
            }
            
          }).catch(err => console.log(err))
      },
      close () {
        this.$emit('close')
        this.filename = ""
        this.file = null
        this.loading = false
        this.disabled = true
        this.floor = null
        this.type = null
        this.id = null
      },
    },
  }
</script>