<template>
  <v-hover v-slot="{ hover }" open-delay="200">
    <v-card>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon v-if="editing" color="primary">mdi-pencil</v-icon>
          <v-icon v-else>mdi-package-variant</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-subtitle>name</v-list-item-subtitle>
          
          <v-list-item-title>
            <v-text-field
              v-if="editing"
              v-model="localValue"
              @blur="emitValue"
              ref="input"
              hide-details
              dense
              class="p-0 m-0"
            ></v-text-field>
            <span v-else>{{ localValue }}</span>
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action :class="{ 'd-none': !hover }">
          <div>
            <v-btn icon v-if="editing">
              <v-icon color="primary" small >mdi-content-save</v-icon>
            </v-btn>
            <v-btn icon v-else>
              <v-icon color="primary" small @click="toggleEditing">mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon color="info" small @click="copyContent">mdi-content-copy</v-icon>
            </v-btn>
          </div>
        </v-list-item-action>
      </v-list-item>
    </v-card>
  </v-hover>
</template>


<script>
export default {
  name: 'InlineTextInput',
  props: {
    value: { type: String, default: '' },
    icon: { type: String, default: 'mdi-package-variant'},

  },
  data() {
    return {
      localValue: this.value,
      editing: false,
    }
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
  },
  methods: {
    emitValue(from) {
      if (this.localValue === this.value) {
        this.$emit('input', this.localValue)
        this.editing = false
      } else { 
        this.$emit('input', this.localValue)
        this.$toast.success('Value saved')
        this.editing = false
      }
      console.log(this.editing)
    },
    toggleEditing() {
      this.editing = !this.editing
      if (this.editing) {
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
      }
    },
    copyContent() {
      navigator.clipboard.writeText(this.localValue).then(() => {
        this.$toast.success('Copied to clipboard')
      }).catch(err => {
        this.$toast.error('Failed to copy')
      });
    },
  },
}
</script>